import { FloatingLabel, Form } from 'react-bootstrap';
import { cardValidator } from '@property-folders/common/util/cc-validation';
import { useEffect, useState } from 'react';

interface Props {
  setValue: (month: string) => void;
  flagExpiryInvalid: boolean;
  expiryValid: boolean;
  setFlagExpiryInvalid: (flag: boolean) => void;
  setValid: (valid: boolean) => void;
  setFlagInvalid: (flag: boolean) => void;
}

export function CardExpiryMonth({
  setValue,
  setFlagExpiryInvalid,
  flagExpiryInvalid,
  expiryValid,
  setValid,
  setFlagInvalid
}: Props) {
  const [cardExpiryMonth, setCardExpiryMonth] = useState('');
  const [monthValid, setMonthValid] = useState(false);
  const [flagMonthInvalid, setFlagMonthInvalid] = useState(false);

  useEffect(() => {
    setValid(monthValid);
    setFlagInvalid(flagMonthInvalid);
    setValue(cardExpiryMonth);
  }, [flagMonthInvalid, monthValid, cardExpiryMonth]);

  return <FloatingLabel label='Expiry Month' className='flex-grow-1 common-label'>
    <Form.Control
      placeholder=''
      type='number'
      minLength={2}
      maxLength={2}
      min={1}
      max={12}
      autoComplete='cc-exp-month'
      isInvalid={(flagMonthInvalid && !monthValid) || (flagExpiryInvalid && !expiryValid)}
      onChange={(e) => {
        setFlagMonthInvalid(false);
        setFlagExpiryInvalid(false);
        let mutableValue = e.target.value.replace(/\D/g, '');

        if (mutableValue.length > 2) {
          mutableValue = parseInt(mutableValue).toString().slice(0, 2);
        }
        if (mutableValue) e.target.value = mutableValue;
        setCardExpiryMonth(mutableValue);
        const { isValid } = cardValidator.expirationMonth(mutableValue);
        setMonthValid(isValid);
      }}
      onBlur={(e) => {
        let mutableValue = e.target.value.replace(/\D/g, '');

        const { isValid } = cardValidator.expirationMonth(mutableValue);
        setMonthValid(isValid);
        if (parseInt(mutableValue) === 0) {
          mutableValue = '';
          e.target.value = mutableValue;
          setFlagMonthInvalid(true);

        } else if (mutableValue) {
          mutableValue = mutableValue.padStart(2, '0');
          e.target.value = mutableValue;
        }
        setCardExpiryMonth(mutableValue);
        if (!isValid) {
          setFlagMonthInvalid(true);
        }

        setValue(cardExpiryMonth);
      }}
    />

  </FloatingLabel>;
}
