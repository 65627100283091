import { Col, Container, Form, Row } from 'react-bootstrap';
import { CardName } from './CardName';
import { CardNumber } from './CardNumber';
import { CardExpiryMonth } from './CardExpiryMonth';
import { CardExpiryYear } from './CardExpiryYear';
import { CardCvn } from './CardCvn';
import { useEffect, useState } from 'react';
import { CardType, getCardType } from '../CardLogo';
import { cardValidator } from '@property-folders/common/util/cc-validation';
import { clsJn } from '@property-folders/common/util/classNameJoin';

export type CardDetails = {
  name: string;
  cvn: string;
  number: string;
  expiryMonth: string;
  expiryYear: string;
  cardType: CardType;
};

interface Props {
  setValue: (cardDetails: CardDetails) => void;
  setValid: (valid: boolean) => void;
}

export function CardDetails({
  setValid,
  setValue
}: Props) {
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState<CardType>(CardType.UNKNOWN);
  const [cardName, setCardName] = useState('');
  const [cardNameValid, setCardNameValid] = useState(false);
  const [cardCvn, setCardCvn] = useState('');
  const [cardExpiryMonth, setCardExpiryMonth] = useState('');
  const [cardExpiryYear, setCardExpiryYear] = useState('');
  const [ccValid, setCcValid] = useState(false);
  const [cvnValid, setCvnValid] = useState(false);
  const [monthValid, setMonthValid] = useState(false);
  const [flagMonthInvalid, setFlagMonthInvalid] = useState(false);
  const [yearValid, setYearValid] = useState(false);
  const [flagYearInvalid, setFlagYearInvalid] = useState(false);
  const [expiryValid, setExpiryValid] = useState(false);
  const [flagExpiryInvalid, setFlagExpiryInvalid] = useState(false);

  const noExpiryInvalidMessage = !(
    (!monthValid && flagMonthInvalid)
    || (!yearValid && flagYearInvalid)
    || (!expiryValid && flagExpiryInvalid)
  );

  useEffect(() => {
    setValue({
      name: cardName,
      cvn: cardCvn,
      number: cardNumber,
      expiryMonth: cardExpiryMonth,
      expiryYear: cardExpiryYear,
      cardType: getCardType(cardNumber)
    });

    if (!(cardExpiryMonth && cardExpiryYear)) {
      setValid(false);
      return;
    }

    // Checking 10 years in the future is the job of the year field
    const { isValid } = cardValidator.expirationDate(`${cardExpiryMonth}/${cardExpiryYear}`);
    setExpiryValid(isValid);
    if (!isValid) {
      setFlagExpiryInvalid(true);
    }

    setValid(cardNameValid && ccValid && cvnValid && isValid);
  }, [cardName, cardNumber, cardCvn, cardExpiryYear, cardExpiryMonth, cardType]);

  return <Container fluid className='mb-3 mb-lg-0'>
    <Form>
      <Row><h5>Credit Card Details</h5></Row>
      <Row>
        <Col>
          <CardName
            setValue={setCardName}
            setValid={setCardNameValid}
          />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col>
          <CardNumber
            setValue={setCardNumber}
            setValid={setCcValid}
            setCardType={setCardType}
          />
        </Col>
      </Row>

      <Row className='mt-2'>
        <Col>
          <CardExpiryMonth
            setValue={setCardExpiryMonth}
            expiryValid={expiryValid}
            setFlagExpiryInvalid={setFlagExpiryInvalid}
            flagExpiryInvalid={flagExpiryInvalid}
            setValid={setMonthValid}
            setFlagInvalid={setFlagMonthInvalid}
          />
        </Col>

        <Col>
          <CardExpiryYear
            setValue={setCardExpiryYear}
            expiryValid={expiryValid}
            setFlagExpiryInvalid={setFlagExpiryInvalid}
            flagExpiryInvalid={flagExpiryInvalid}
            setValid={setYearValid}
            setFlagInvalid={setFlagYearInvalid}
          />
        </Col>
      </Row>

      <Row>
        <div className={clsJn({ 'invalid-feedback': true, valid: noExpiryInvalidMessage })}>{
          (!monthValid && flagMonthInvalid)
            ? 'Please enter a valid month'
            : (!yearValid && flagYearInvalid)
              ? 'Please enter a year up to 10 years in future'
              : (!expiryValid && flagExpiryInvalid)
                ? 'Please ensure your date is this month or in future'
                : 'Placeholder'
        }</div>
      </Row>

      <Row className='mt-2'>
        <Col>
          <CardCvn
            setValue={setCardCvn}
            setValid={setCvnValid}
          />
        </Col>
      </Row>
    </Form>
  </Container>;
}
