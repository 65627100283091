import { WrField } from '../CommonComponentWrappers';
import {
  DivisionType,
  divisionTypeOptions,
  FormCode, FormFamilyState, MaterialisedPropertyData,
  PortionOfLandAutomaticClauseCondition, PortionOfLandDwellingDetails,
  TitleDivision,
  twelveMonthsAfterContractDate
} from '@property-folders/contract';
import { quadStateYesNoUnknownOpts } from '@property-folders/common/util/pdfgen/constants';
import { ProposedLotAnnexure } from '../ProposedLotAnnexure';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { insertAllImmerPath, useLightweightTransaction, useTransactionField } from '../../../hooks/useTransactionField';
import { FormContext } from '../../../context/FormContext';
import { FormTypes } from '@property-folders/common/yjs-schema/property/form';
import { useYdocBinder } from '../../../hooks/useYdocBinder';
import { Clause as PropertyClause } from '@property-folders/contract/property';
import { ClausesApi } from '@property-folders/common/client-api/clausesApi';
import { Clause as ApiClause } from '@property-folders/common/types/Clause';
import { QuadStateDualBoolCheckboxes } from '../../../display/form/QuadStateRadioCheckboxes';
import { removeClausesManagedBy } from '@property-folders/common/util/clauses-immer';
import { v4 } from 'uuid';
import { checkBuildPath } from '@property-folders/common/util/build-skeleton';
import { generateParentPath, getValueByPath } from '@property-folders/common/util/pathHandling';
import { dueDateText } from './DwellingDetails';
import { Accordion, Alert } from 'react-bootstrap';
import { Predicate } from '@property-folders/common/predicate';
import { Icon } from '../../Icon';
import { useReactRouterData } from '../../../hooks/useReactRouterHooks';
import { RouterData } from '@property-folders/web/src/App';

export const managedByTitleDivision = 'titleDivision';

export function PortionOfLandDetails({ noAttachPlan = false, editable = true, startExpanded = false }: {
  noAttachPlan?: boolean,
  editable?: boolean,
  startExpanded?: boolean
}) {
  const loaderData = useReactRouterData<RouterData>();
  const { transactionRules: dataTransRules, formName: formCode } = useContext(FormContext);
  const formFamily = FormTypes[formCode]?.formFamily;
  const clausesConfigurable = formFamily === FormCode.RSC_ContractOfSale;
  const { value: contractFamilyState } = useLightweightTransaction<FormFamilyState>({
    parentPath: `formStates.${formFamily || FormCode.RSC_ContractOfSale}`,
    bindToMetaKey: true
  });
  const clauseUUID = contractFamilyState?.clauseChildId;
  const clausesPath = clauseUUID ? `clausesByFamily.[${clauseUUID}].clauses` : 'clauses';

  const { handleUpdate: setAutoClause } = useTransactionField<boolean>({
    parentPath: 'titleDivision',
    myPath: 'autoClause'
  });
  const { value: clauses } = useTransactionField<PropertyClause[]>({
    parentPath: clausesPath
  });
  const { updateDraft: updateRootDraft } = useYdocBinder<MaterialisedPropertyData>({ path: [] });
  const { value: titleDivision } = useLightweightTransaction<TitleDivision>({
    parentPath: 'titleDivision'
  });
  const { value: dwelling } = useLightweightTransaction<PortionOfLandDwellingDetails>({
    parentPath: 'dwelling'
  });
  const selectedClause = clauses?.find(clause =>
    'managedBy' in clause && clause.managedBy === managedByTitleDivision);
  const selectedClauseCondition = selectedClause && 'condition' in selectedClause && selectedClause.condition
    ? selectedClause.condition
    : undefined;

  const [clauseOptions, setClauseOptions] = useState<ApiClause[] | undefined>(undefined);

  useEffect(() => {
    ClausesApi.listClausesForFormAndProperty({ propertyId: loaderData.transId, formCode, markdown: true, conditional: true }).then(r => {
      if (!r?.results) {
        setClauseOptions([]);
        return;
      }

      setClauseOptions(r.results);
    });
  }, []);

  useEffect(() => {
    if (titleDivision && typeof titleDivision.autoClause === 'undefined') {
      setAutoClause(true, true);
    }
  }, [titleDivision?.autoClause]);

  const clauseCondition = decideAutomaticClauseCondition(titleDivision);
  const matchingClauseOption = clauseOptions?.find(clause => clause.conditionallyRequiredWhen === clauseCondition);
  useEffect(() => {
    if (!clauseUUID) return;
    if (!clausesConfigurable) return;
    if (!updateRootDraft) return;
    if (titleDivision?.autoClause) {
      if (selectedClauseCondition === clauseCondition) return;
      if (!matchingClauseOption) return;

      const clause = matchingClauseOption;
      updateRootDraft(draftRoot => {
        checkBuildPath(generateParentPath(clausesPath), dataTransRules, draftRoot);
        const draft: PropertyClause[] = getValueByPath(clausesPath, draftRoot);

        const toInsert: PropertyClause[] = [{
          id: v4(),
          title: clause.title,
          text: clause.markdownClause?.replace(/[\r\n]+/g, '\n') || '',
          importId: String(clause.clauseId),
          required: true,
          canEdit: false,
          editing: false,
          hash: clause.hash,
          condition: clauseCondition,
          managedBy: managedByTitleDivision
        }];

        if (!draft) {
          insertAllImmerPath(draftRoot, toInsert, clausesPath, dataTransRules);
          return;
        }

        removeClausesManagedBy(draft, managedByTitleDivision);
        draft.push(...toInsert);
      });
    } else {
      updateRootDraft(draftRoot => {
        checkBuildPath(generateParentPath(clausesPath), dataTransRules, draftRoot);
        const draft: PropertyClause[] = getValueByPath(clausesPath, draftRoot);

        removeClausesManagedBy(draft, managedByTitleDivision);
      });
    }
  }, [clausesConfigurable, clauseCondition, titleDivision?.autoClause, selectedClauseCondition, !!updateRootDraft, clauseOptions?.length, matchingClauseOption, clauseUUID]);

  const [expanded, setExpanded] = useState(!!startExpanded);
  const summary = useMemo(() => {
    const missing: boolean[] = [
      !titleDivision?.depositChoice,
      typeof titleDivision?.hasDevelopmentAuthorisation === 'undefined',
      !(titleDivision?.planDue12 || titleDivision?.planDueDate),
      !titleDivision?.proposedLots,
      !titleDivision?.plan
    ].filter(Predicate.isTruthy);

    if (missing.some(Boolean)) {
      return <span className='text-danger'>Missing information</span>;
    }

    const lotsText = [
      titleDivision?.depositChoice === 'commDiv' ? 'Community Lot(s)' : 'Torrens Allotment(s)',
      titleDivision?.proposedLots
    ].filter(Predicate.isTruthy).join(' ');

    const pills = [
      lotsText
    ].filter(Predicate.isTruthy);

    return <span>
      {pills.join(', ')}
    </span>;
  }, [expanded]);

  return <div className='mt-3'>
    <Accordion defaultActiveKey={startExpanded ? '0' : undefined} flush onSelect={e => {
      setExpanded(e != null);
    }}>
      <Accordion.Item eventKey='0'>
        <Accordion.Header>
          <div className='w-100 pe-4 d-flex align-items-baseline justify-content-between gap-2'>
            <div className={'fs-4'}>
            Portion of Land Details
            </div>
            {!expanded && <div
              className={'overflow-hidden text-overflow-ellipsis d-flex flex-row gap-1'}
              style={{ lineHeight: '1.5rem' }}>
              {summary}
            </div>}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {!editable && <div className='d-flex flex-column'>
            <div>Proposed {titleDivision?.depositChoice === 'commDiv' ? 'Lot' : 'Allotment'}(s) <b>{titleDivision?.proposedLots || 'not provided'}</b> in proposed {titleDivision?.depositChoice === 'commDiv' ? 'Community Plan' : 'Land Division'}</div>
            <div>Plan uploaded: <b>{titleDivision?.plan ? 'yes' : 'no'}</b></div>
            <div>
                Deposit of Plan due: <b>{dueDateText(titleDivision?.planDue12, titleDivision?.planDueDate, '12 months after contract date')}</b>
            </div>
          </div>}
          {editable && <>
            <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <WrField.Select
                  parentPath='titleDivision'
                  myPath='depositChoice'
                  name='depositChoice'
                  options={divisionTypeOptions}
                  canClear={true}
                  label='Type of Division'
                />
              </div>
            </div>
            {titleDivision?.depositChoice && [DivisionType.Community, DivisionType.Torrens].includes(titleDivision.depositChoice) &&
              <div className='mt-2'>
                <div className='lead mt-2'>Plan Details</div>
                <div className='d-flex w-100 gapped-row'>
                  <div className='d-flex w-100 flex-wrap flex-row gap-2'>
                    <div style={{ flexGrow: 1, width: '200px' }} className=''>
                      <WrField.Control
                        name='proposedLots'
                        label={titleDivision.depositChoice === DivisionType.Torrens
                          ? 'Proposed Allotment(s) being sold'
                          : 'Proposed Lot(s) being sold'}
                        parentPath='titleDivision'
                        myPath='proposedLots'
                        spellCheck={false}/>
                    </div>
                    {!noAttachPlan && <div style={{ flexGrow: 2, width: '300px' }} className=''>
                      <ProposedLotAnnexure/>
                    </div>}
                  </div>
                </div>
              </div>}
            <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <QuadStateDualBoolCheckboxes
                  parentPath='titleDivision'
                  yesNoBoolRelPath='hasDevelopmentAuthorisation'
                  unknownBoolRelPath='_hasDevelopmentAuthorisation'
                  label='Has Development Authorisation been obtained?'
                  options={quadStateYesNoUnknownOpts}
                  inline={true}
                />
              </div>
            </div>
            <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                {!clausesConfigurable && clauseCondition !== PortionOfLandAutomaticClauseCondition.None && <>
                  <Alert variant={'info'} className='d-flex flex-row gap-2 align-items-center'>
                    <Icon name={'info'} />
                    <span>Based on the answers provided, there will be Additional Conditions relating to the proposed division in the Template Contract</span>
                  </Alert>
                </>}
                {clausesConfigurable && <>
                  <div className='lead'>Additional Conditions</div>
                  <WrField.BoolCheck
                    parentPath='titleDivision'
                    name='autoClause'
                    myPath='autoClause'
                    label='Automatically manage Additional Conditions relating to the proposed division'
                  />
                  <em className='text-muted'>{getClauseName(clauseCondition)}</em>
                </>}
              </div>
            </div>
            <div className='d-flex w-100 flex-wrap mt-2'>
              <WrField.CheckRadio
                parentPath='titleDivision'
                name='planDue12'
                myPath='planDue12'
                label='What is the Deposit of Plan due date under the Contract going to be?'
                radioType='checkbox'
                valueType='boolean'
                options={twelveMonthsAfterContractDate}
              />
            </div>
            {!titleDivision?.planDue12 && <div className='d-flex w-100 flex-wrap mt-2'>
              <div className='flex-grow-1'>
                <WrField.Control
                  parentPath='titleDivision'
                  name='planDueDate'
                  myPath='planDueDate'
                  label='Deposit of Plan due date'
                  type='date'
                  dateFromToday={true}
                  useCanonical={true}
                />
              </div>
            </div>}
          </>}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>;
}

function decideAutomaticClauseCondition(titleDivision?: TitleDivision): PortionOfLandAutomaticClauseCondition {
  /**
   * -	If Torrens and no Development Authorisation, then use clause 3 (Subject to Development Authorisation and Deposit of Plan - by Vendor).
   * -	If Community and no Development Authorisation, then use clause 4 (Subject to Development Authorisation and Deposit of Community Plan - by Vendor).
   * -	If Torrens and Development Authorisation has been obtained, then use clause 1 (Subject to Deposit of Plan - by Vendor).
   * -	If Community and Development Authorisation has been obtained, then use clause 2 (Subject to Deposit of Community Plan - by Vendor).
   */
  const hasDevelopmentAuthorisation = Boolean(titleDivision?.hasDevelopmentAuthorisation);
  switch (titleDivision?.depositChoice) {
    case DivisionType.Torrens:
      return hasDevelopmentAuthorisation
        ? PortionOfLandAutomaticClauseCondition.TorrensAndYesDevAuth
        : PortionOfLandAutomaticClauseCondition.TorrensAndNoDevAuth;
    case DivisionType.Community:
      return hasDevelopmentAuthorisation
        ? PortionOfLandAutomaticClauseCondition.CommunityAndYesDevAuth
        : PortionOfLandAutomaticClauseCondition.CommunityAndNoDevAuth;
    default:
      return PortionOfLandAutomaticClauseCondition.None;
  }
}

function getClauseName(clauseCondition: PortionOfLandAutomaticClauseCondition): string {
  switch (clauseCondition) {
    case PortionOfLandAutomaticClauseCondition.TorrensAndNoDevAuth:
      return 'Subject to Development Authorisation and Deposit of Plan - by Vendor';
    case PortionOfLandAutomaticClauseCondition.CommunityAndNoDevAuth:
      return 'Subject to Development Authorisation and Deposit of Community Plan - by Vendor';
    case PortionOfLandAutomaticClauseCondition.TorrensAndYesDevAuth:
      return 'Subject to Deposit of Plan - by Vendor';
    case PortionOfLandAutomaticClauseCondition.CommunityAndYesDevAuth:
      return 'Subject to Deposit of Community Plan - by Vendor';
    case PortionOfLandAutomaticClauseCondition.None:
    default:
      return 'N/A';
  }
}
