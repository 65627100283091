import { Button, Form, Modal } from 'react-bootstrap';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { AsyncButton } from '../dragged-components/AsyncButton';

export interface AssignAgentModalProps {
  folderId?: number;
  folderName?: string;
  show: boolean;
  setShow: (show: boolean) => void;
  refetch: () => Promise<unknown>;
}

export function DeleteFolderModal(props: AssignAgentModalProps) {
  const { folderId, folderName, show, setShow, refetch } = props;

  if (typeof folderId !== 'number') {
    return <></>;
  }

  return <Modal
    show={show}
    onHide={() => setShow(false)}
  >
    <Modal.Header>
      <Modal.Title>Delete Folder</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <Form.Text>
        Warning: Deleting this folder (<strong>{folderName}</strong>) is permanent and cannot be undone. The documents inside will not be deleted, but they will no longer be organised in this folder.
      </Form.Text>
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={() => setShow(false)}>Cancel</Button>

      <AsyncButton
        variant='danger'
        onClick={async () => {
          await AjaxPhp.deleteFolder({ folderId });
          refetch && await refetch();
          setShow(false);
        }}
      >
        Delete
      </AsyncButton>
    </Modal.Footer>
  </Modal>;
}
