import { FloatingLabel, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

interface Props {
  setValue: (name: string) => void;
  setValid: (valid: boolean) => void;
}

export function CardName({
  setValue,
  setValid
}: Props) {
  const [cardName, setCardName] = useState('');
  const [flagEmptyName, setFlagEmptyName] = useState(false);

  useEffect(() => {
    setValid(cardName.trim().length > 0);
    setValue(cardName);
  }, [cardName]);

  return <FloatingLabel label='Name on card' className='flex-grow-1 common-label'>
    <Form.Control
      placeholder=''
      type='text'
      autoComplete='cc-name'
      isInvalid={flagEmptyName && !cardName}
      onChange={(e) => {
        setFlagEmptyName(false);
        setCardName(e.target.value);
      }}
      onBlur={e => {
        setFlagEmptyName(true);
      }}
    />
    <div className='invalid-feedback'>Please enter the cardholder name</div>
  </FloatingLabel>;
}
