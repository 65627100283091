import { FloatingLabel, Form } from 'react-bootstrap';
import { cardValidator } from '@property-folders/common/util/cc-validation';
import { useEffect, useState } from 'react';

interface Props {
  setValue: (year: string) => void;
  flagExpiryInvalid: boolean;
  expiryValid: boolean;
  setFlagExpiryInvalid: (flag: boolean) => void;
  setValid: (valid: boolean) => void;
  setFlagInvalid: (flag: boolean) => void;
}

const yearStart = parseInt((new Date).getFullYear().toString().slice(2, 4));
const yearEnd = yearStart + 10;

export function CardExpiryYear({
  setValue,
  flagExpiryInvalid,
  setFlagExpiryInvalid,
  expiryValid,
  setValid,
  setFlagInvalid
}: Props) {
  const [cardExpiryYear, setCardExpiryYear] = useState('');
  const [yearValid, setYearValid] = useState(false);
  const [flagYearInvalid, setFlagYearInvalid] = useState(false);

  useEffect(() => {
    setValid(yearValid);
    setFlagInvalid(flagYearInvalid);
    setValue(cardExpiryYear);
  }, [flagYearInvalid, yearValid, cardExpiryYear]);

  return <FloatingLabel label='Expiry Year' className='flex-grow-1 common-label'>
    <Form.Control
      placeholder=''
      type='number'
      minLength={2}
      maxLength={2}
      min={yearStart}
      max={yearEnd} // it's what amazon does, it's gotta be a safe bet.
      autoComplete='cc-exp-year'
      isInvalid={(flagYearInvalid && !yearValid) || (flagExpiryInvalid && !expiryValid)}
      onChange={(e) => {
        setFlagYearInvalid(false);
        setFlagExpiryInvalid(false);
        let mutableValue = e.target.value.replace(/\D/g, '');

        if (mutableValue.length > 2) {
          mutableValue = parseInt(mutableValue).toString().slice(0, 2);
        }
        if (mutableValue) e.target.value = mutableValue;
        setCardExpiryYear(mutableValue);
        const { isValid } = cardValidator.expirationYear(mutableValue, 10);
        setYearValid(isValid);
        setValid(isValid);
      }}
      onBlur={(e) => {
        let mutableValue = e.target.value.replace(/\D/g, '');
        const { isValid } = cardValidator.expirationYear(mutableValue, 10);
        setYearValid(isValid);
        if (parseInt(mutableValue) === 0) {
          mutableValue = '';
          setFlagYearInvalid(true);
        } else if (mutableValue) {
          mutableValue = mutableValue.padStart(2, '0');
        }
        e.target.value = mutableValue;
        setCardExpiryYear(mutableValue);
        if (!isValid) {
          setFlagYearInvalid(true);
        }

        setValue(cardExpiryYear);
        setValid(isValid);
      }}
    />

  </FloatingLabel>;

}
