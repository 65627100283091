import { useEffect, useState } from 'react';
import { Button, Modal, FormControl, FormLabel } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { AjaxPhp } from '@property-folders/common/util/ajaxPhp';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { AsyncButton } from '../dragged-components/AsyncButton';

export interface AssignAgentModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  refetch: () => Promise<unknown>;
}

export function CreateFolderModal(props: AssignAgentModalProps) {
  const { show, setShow, refetch } = props;
  const { data: session } = AuthApi.useGetAgentSessionInfo();
  const [folderName, setFolderName] = useState<string>('');
  const [entityId, setEntityId] = useState<number | null>(null);

  if (!session?.entities || session.entities.length === 0) {
    return <></>;
  }

  useEffect(() => {
    // Set initial entity.
    setEntityId(session.entities[0].entityId);
  }, []);

  // Only show the dropdown if users have an option of different entities.
  const options =
    session.entities.length > 1
      ? session.entities.map(({ customerFacingName, entityId, name, profileName }) => (
        {
          label: profileName || customerFacingName || name,
          value: entityId
        }
      ))
      : null;

  const onClose = () => {
    setShow(false);
    setFolderName('');
  };

  return <Modal show={show} onHide={onClose}>
    <Modal.Header>
      <Modal.Title>Create Folder</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <FormLabel htmlFor='create-folder-name'>What do you want to call this folder?</FormLabel>
      <FormControl id='create-folder-name' autoFocus value={folderName} onChange={event => setFolderName(event.target.value)} placeholder='Enter a folder name' />

      {options && (<>
        <FormLabel htmlFor='create-folder-entity' class='pt-3 mb-2'>Which agency should the document be created under?</FormLabel>
        <Typeahead
          filterBy={() => true}
          id='create-folder-entity'
          placeholder='Select an agency'
          className='searchbar-dropdown'
          options={options}
          multiple={false}
          clearButton
          onChange={(selections) => {
            // Typeahead has 💩 types, so use our own from `options`.
            const selected: typeof options[number] | undefined = (selections as typeof options)[0];
            setEntityId(selected === undefined ? null : selected.value);
          }}
        />
      </>)}
    </Modal.Body>

    <Modal.Footer>
      <Button variant='outline-secondary' onClick={onClose}>Cancel</Button>

      <AsyncButton
        disabled={folderName.length === 0 || entityId === null}
        onClick={async () => {
          if (entityId === null) {
            return;
          }

          await AjaxPhp.createFolder({ entityId, folderName });
          refetch && await refetch();
          setShow(false);
          setFolderName('');
        }}
      >
        Create
      </AsyncButton>
    </Modal.Footer>
  </Modal>;
}
