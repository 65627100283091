import { Button, Col, Dropdown, Offcanvas, Row, SplitButton } from 'react-bootstrap';
import { useTransactionField } from '../../hooks/useTransactionField';
import { TransactionConsumerProps } from '@property-folders/common/types/Transaction';
import React, { MutableRefObject, useEffect, useState, useContext, useMemo, ReactElement } from 'react';
import './CollectionEditor.scss';
import clsJn from '@property-folders/common/util/classNameJoin';
import { DragDropContext, Draggable, Droppable, DropResult, ResponderProvided } from 'react-beautiful-dnd';
import { useYdocBinder } from '../../hooks/useYdocBinder';
import { Icon } from '../Icon';
import { uuid } from 'short-uuid';
import { LineageContext } from '../../hooks/useVariation';
import { getValueByPath, normalisePathToStr } from '@property-folders/common/util/pathHandling';
import { Predicate } from '@property-folders/common/predicate';
import { findIndex, uniqWith } from 'lodash';
import { SetHeaderActionsFn } from '../Wizard/WizardStepPage';
import { ListHistoryFn, buildListHistory, determineReplacedRemovedLocked } from '@property-folders/common/util/dataExtract';
import { createPortal } from 'react-dom';
import { PathString, PathType } from '@property-folders/contract/yjs-schema/model';
import { canonicalisers, composeErrorPathClassName } from '@property-folders/common/util/formatting';
import { ShowGuidanceNotesButton } from '../guidance/ShowGuidanceNotesButton';
import { usePrevious } from 'react-use';
import { DataGeneration } from '@property-folders/common/util/dataExtractTypes';
import { WizardDisplayContext } from '../../context/WizardContexts';
import { FormUserInteractionContext } from '../../context/FormUserInteractionContext';

type RestoreFunc = (restoreItem: {id: string, [other:string]: any}) => void;

export type EditorListChildProps = TransactionConsumerProps & {
  hideDelete?: boolean
  removable?: boolean
  editable?: boolean
  // Not all children use all of the props below
  primaryId?: string
  myIndex?: string|number
  collectionLength?: number
  thisLevel: number,
  onDelete?: <T = any>(element: T)=>void
  onUpdate?: <T = any>(element: T)=>void
  onInsert?: <T = any>(element: T)=>void
  onUpdateBegin?: <T = any>(element: T)=>void
  onUpdateCancel?: <T = any>(element: T)=>void
  duplicate?: boolean;
  autoFocus?: boolean;
  collectionRemovedList?: any[]
  onCollectionRestoreItem?: (restoreElement: any)=>void
  onCollectionRemoveItemHereForChild?: (removalChildPath: PathType)=>void;
  isHeader?: boolean;
  isNewRow?: boolean;
  primaryIdAbsPath: PathString
};
type CollectionEditorProps = TransactionConsumerProps & {
  childItemRenderer: (props: EditorListChildProps & any) => JSX.Element
  overrideAdd?: ()=>any | ({label: string, fn: ()=>any}[])
  onInsert?: <T = any>(element: T)=>void
  onDelete?: <T = any>(element: T)=>void
  onReorder?: <T = any>(element: T[])=>void
  onUpdateBegin?: <T = any>(element: T)=>void
  onUpdateCancel?: <T = any>(element: T)=>void
  onUpdate?: <T = any>(element: T) => void
  emptyListErrorMessage?: string | JSX.Element
  allowAdd?: boolean
  autoAddFirst?: boolean | number // If a number is specified, this defines the minimum number of rows to have and to add
  title?: string | ReactElement
  titlePlural?: string
  primaryIdAbsPath?: PathType
  level?: number
  compact?: boolean
  setPrimaryIdToFirst?: boolean
  makePrimaryFirstElement?: boolean,
  childProps?: {[prop: string]: any}
  expansionTreeMarks?: boolean
  endAddButtonText?: string
  itemNoun?: string
  restorationFieldDisplay?: string | ((item:any)=>(JSX.Element|string)); // 'myPath' to node of child, eg fullLegalName
  addButtonTitle?: boolean
  indentLevel?: number
  autoAddNew?: boolean
  addTooltip?: string
  guidanceUnderTitle?: {title: string, body: JSX.Element}
  duplicateCheck?: <T = any>(items: T[]) => T[]
  collectionMaskingText?: string
  setHeaderActions?: SetHeaderActionsFn
  allowDragAndDrop?: boolean
  dragAndDropConfig?: {
    handleClass?: string
    draggableClass?: string
  }
  variationDetectionFunction?: ListHistoryFn,
  dataModelDoesNotRetainPrevious?: boolean
  externalRestore?: RestoreFunc
  deletionListPortalRef?: MutableRefObject<HTMLDivElement>
  restoreOnlyThisVariationDeleted?: boolean
  hasHeaderRow?: boolean
  className?: string
  maximum?: number
  requiredForAutoAddRelativePaths?: PathType[]
  sectionProperty?: string
  emptyRecordProperties?: string[]
  isChildReadonly?: (child: any) => boolean
  passAddToChildInstead?: boolean,
  addButtonAboveList?: boolean
  renderBelowWithAddAction?: (onAdd: ()=>void) => ReactElement
  hrEnable?: boolean
  afterCollection?: JSX.Element
};

const addButton = (handler: ()=>void, addText= '', addTooltip?: string) => {
  return <Button
    variant='light'
    className={!addText ? 'title-btn' : ''}
    onClick={handler}
    title={addTooltip}
  >
    {addText && <span className='d-block fs-6'>{addText}</span>}
    {!addText && <span className='d-block'>Add</span>}
  </Button>;
};

/**
 * @param param0.overrideAdd Call this instead of the usual item insert if present. It will generate
 *  the object to insert
 * @param param0.makePrimaryFirstElement Reorder the element matching primary ID to first position
 * @param param0.addButtonTitle Put the button next to the title rather than at the bottom. Use
 *  case: You have a collection under another collection and don't want to buttons on top of
 *  eachother at the bottom (or it's been indented so the second button is out of line)
 * @param param0.indentLevel How much to indent the fields by. 0 means top level or no indent
 * @param param0.autoAddNew Automatically adds a new row when one of the fields in the row data
 *  model is populated. Relies on binder detecting that an array index that doesn't exist was
 *  passed in, and creating the row accordingly, because this component generates the next index in
 *  the list
 * @returns
 */
export const CollectionEditor = ({
  makePrimaryFirstElement, // Pull the primary ID to the top of the list when it changes
  setPrimaryIdToFirst = false,
  childItemRenderer: ChildElement,
  allowAdd: allowAdd = true,
  autoAddFirst = false,
  title,
  titlePlural,
  myPath,
  parentPath,
  ydocForceKey,
  bindToMetaKey,
  overrideAdd,
  onDelete,
  onUpdateBegin,
  onUpdateCancel,
  onUpdate,
  onReorder,
  primaryIdAbsPath,
  level = 1,
  compact = true,
  childProps,
  expansionTreeMarks = false,
  endAddButtonText,
  itemNoun,
  restorationFieldDisplay,
  addButtonTitle = false,
  indentLevel,
  autoAddNew = false,
  addTooltip,
  guidanceUnderTitle,
  duplicateCheck,
  collectionMaskingText,
  setHeaderActions,
  allowDragAndDrop = false,
  dragAndDropConfig,
  variationDetectionFunction,
  dataModelDoesNotRetainPrevious: expectDataModelToMaintainPrevious,
  externalRestore,
  deletionListPortalRef,
  restoreOnlyThisVariationDeleted,
  hasHeaderRow = false,
  className: collectionDisplayClassName,
  maximum,
  requiredForAutoAddRelativePaths,
  sectionProperty,
  emptyRecordProperties,
  isChildReadonly,
  passAddToChildInstead,
  renderBelowWithAddAction,
  addButtonAboveList,
  hrEnable,
  emptyListErrorMessage,
  afterCollection
}: CollectionEditorProps): JSX.Element => {
  if (!endAddButtonText && itemNoun) endAddButtonText = `Add ${itemNoun}`;
  const { variationsMode, snapshotHistory } = useContext(LineageContext);
  const { focusErrList } = useContext(WizardDisplayContext);
  const { userShouldSeeAllValidation } = useContext(FormUserInteractionContext);
  const [showAdvice, setShowAdvice] = useState(false);
  const [hasInserted, setHasInserted] = useState(false);
  const [showRemoved, setShowRemoved] = useState(false);
  const [blockNewAutoRow, setBlockNewAutoRow] = useState(false);
  const { value: valuesUntyped, fullPath, handleInsert: insertItem, loading, readOnly, mergedRules } = useTransactionField({ parentPath, myPath, bindToMetaKey, ydocForceKey });

  const emptyListClass = composeErrorPathClassName(fullPath, '');
  const collectionEmptyError = userShouldSeeAllValidation && focusErrList.includes(emptyListClass);
  const values = valuesUntyped as any[]|undefined;
  const { updateDraft } = useYdocBinder({ path: fullPath, ydocForceKey, bindToMetaKey });
  if (readOnly) {
    allowAdd = false;
  }

  const allComplete = useMemo(()=>{
    if (!(Array.isArray(requiredForAutoAddRelativePaths) && requiredForAutoAddRelativePaths.length)) {
      return true;
    }
    return (values??[]).filter(row=>{
      return requiredForAutoAddRelativePaths.filter(path=>{
        const val = getValueByPath(path,row,true);
        if (typeof val === 'boolean') {
          return false;
        }
        if (val === 0) {
          return false;
        }
        if (!val) {
          return true;
        }
        return false;
      }).length > 0;
    }).length === 0;
  }, [values, requiredForAutoAddRelativePaths]);

  const maximumReached = typeof maximum === 'number' && (values?.length??0) >= maximum;

  const [duplicateItems, setDuplicateItems] = useState([] as any[]);
  const [isDragging, setIsDragging] = useState(false);

  const primaryIdHook = useTransactionField({ myPath: primaryIdAbsPath });
  const primaryIdValue = primaryIdHook.value;
  const updatePrimaryId = (ref: string) => primaryIdHook.handleUpdate(ref, true);

  const restoreEnable = variationsMode && itemNoun && restorationFieldDisplay;

  const orderList: ReturnType<typeof determineReplacedRemovedLocked>|null = useMemo(()=>{
    if (!(variationsMode && (mergedRules._listPreserveSigningOrder || variationDetectionFunction) && snapshotHistory)) {
      return null;
    }
    const listHistory = buildListHistory(fullPath, snapshotHistory, snapshotHistory.latestExpiry, variationDetectionFunction);

    return determineReplacedRemovedLocked('', listHistory, values, expectDataModelToMaintainPrevious && DataGeneration.CarriedOver);

  }, [mergedRules._listPreserveSigningOrder, values, variationsMode && snapshotHistory, variationDetectionFunction]);

  const removedList = useMemo(()=>{
    if (!restoreEnable) {
      return [];
    }
    if (restoreOnlyThisVariationDeleted && values && orderList) {
      return values.filter(item=>item?._removedMarker && !item?._replacedBy).map(item=>orderList?.find(fi=>fi.id === item.id)?.data);
    }
    if (orderList) {
      return orderList.filter(item=>item.state === DataGeneration.Removed).map(item=>item.data);
    }
    const presentIds = ((values??[]).map((val:any)=>val?.id).filter((a:any)=> typeof a === 'string')) as string[];
    const allHistory = Object.values(snapshotHistory?.data??{})
      .map(snap=>getValueByPath(fullPath, snap, true))
      .filter(Predicate.isNotNullish)
      .flat()
      .filter(snapElem => !(presentIds.includes(snapElem?.id)));
    const dedupHistory = uniqWith(allHistory, (a, b) => a?.id === b?.id);
    return dedupHistory;
  }, [values, snapshotHistory, restoreEnable]);

  const annexurePreviousList = useMemo(()=>{
    if (!orderList || !expectDataModelToMaintainPrevious) {
      return;
    }
    const result = orderList.filter(item=>[DataGeneration.CarriedOver, DataGeneration.Added, DataGeneration.Restored, DataGeneration.Replaced].includes(item.state));

    return result;
  }, [orderList, expectDataModelToMaintainPrevious]);

  useEffect(() => {
    if (!Array.isArray(values) || !duplicateCheck) {
      return;
    }

    const errors = duplicateCheck(values);
    setDuplicateItems(Array.isArray(errors) ? errors : []);
  }, [values]);

  const handleInsert = (shouldFocus = true) => {
    insertItem(overrideAdd?.() ?? {});
    shouldFocus && setHasInserted(true);
  };

  const isRecordEmpty = (record: Record<string, any>) => {
    const hasValue = emptyRecordProperties?.map(p => !!record[p]);
    return hasValue?.every(p => !p);
  };

  useEffect(()=>{
    if (autoAddFirst && !loading && (values === undefined || (Array.isArray(values) && (values.length === 0 || (typeof autoAddFirst === 'number' && values.length < autoAddFirst))))) {
      handleInsert(false);
      if (typeof autoAddFirst === 'number' && autoAddFirst > 1) {
        for (let count = (values?.length??0)+1; count < autoAddFirst; count++) { // Initial value is 1 because we already inserted 0 above
          handleInsert(false);
        }
      }
    }

    //insert new record at end of each section if using sections
    if (sectionProperty && autoAddNew) {
      const insertIndexes: number[] = [];
      let prevSection = '';
      valueList.forEach((v, idx) => {
        if (prevSection && prevSection !== v[sectionProperty] && !isRecordEmpty(valueList[idx - 1])) {
          insertIndexes.push(idx);
        }
        prevSection = v[sectionProperty];
      });

      if (valueList.length !== 0 && !isRecordEmpty(valueList[valueList.length-1])) insertIndexes.push(valueList.length);

      if (insertIndexes?.length) {
        updateDraft?.(draft => {
          insertIndexes.forEach((v, idx) => {
            const newItem = { id: uuid(), [sectionProperty]: valueList[v-1][sectionProperty] };
            draft?.splice(v + idx, 0, newItem);
          });
        });
      }
    }
  }, [values, loading, autoAddFirst]);

  useEffect(()=>{
    if (values?.length === 0) {
      updatePrimaryId('');
    } else if (setPrimaryIdToFirst && values?.length === 1) {
      updatePrimaryId(values[0].id);
    }
  }, [values?.length]);

  const itemCount = Object.keys(values ?? []).length;

  // This seems to work probably because I'm trying to use paths to update as much as possible?
  let valueList = Object.values(values as any[] ?? {});
  if (makePrimaryFirstElement) {
    const ref = primaryIdValue;
    const mIdx = valueList.findIndex(val=> val.id === ref);
    if (mIdx) {
      const firstElem = valueList.splice(mIdx,1);
      valueList = [...firstElem, ...valueList];
    }
  }

  const indentationParam: {[key: string]: any} = {};
  // If the indent level is zero, we don't style anyway
  if (indentLevel) {
    indentationParam.style = {
      marginLeft: `${indentLevel*1.5}rem`,
      marginRight: `${indentLevel*1.5}rem`
    };
  }

  const removeCollectionItemAnyChildPath = (removalChild: PathType) => {
    let removePathStr = normalisePathToStr(removalChild);
    if (!removePathStr.startsWith(fullPath)) {
      console.warn('Attempting to remove an item from a different tree than this collection');
      return;
    }
    removePathStr = removePathStr.slice(fullPath.length+1);
    removePathStr = removePathStr.split('.')[0]??'';
    const collectionItemIdOrIndex = (/\[([\w-]+)\]/.exec(removePathStr)??[])[1];
    if (!collectionItemIdOrIndex) {
      console.warn('couldn\'t find item to remove from path');
    }
    updateDraft?.(collectionDraft => {
      if (!Array.isArray(collectionDraft)) {
        console.warn('No collection to remove from?');
        return;
      }
      const remIndex = collectionDraft.findIndex(colItem=>colItem?.id === collectionItemIdOrIndex);
      if (remIndex === -1) {
        console.warn('removal item not found');
        return;
      }
      collectionDraft.splice(remIndex,1);
    });

  };

  const restoreItemDefaultHandler = (restoreItem: any) => {

    updateDraft?.(list=> {
      if (!Array.isArray(list) || restoreItem == null) {
        return;
      }
      if (!allowAdd) {
        list.splice(0,list.length);
        list.push(restoreItem);
      } else if (orderList) {
        const insertionIndex = findIndex(
          orderList
            ?.filter(or => or.id === restoreItem.id || or.state !== DataGeneration.Removed),
          or=>or.id === restoreItem.id
        );
        list.splice(insertionIndex, 0, restoreItem);
      } else {
        list.push(restoreItem);
      }

    });
  };

  useEffect(()=>{

    if (blockNewAutoRow) {
      setTimeout(()=>setBlockNewAutoRow(false), 500);
    }
  }, [blockNewAutoRow]);

  const restoreItemHandler = externalRestore ?? restoreItemDefaultHandler;

  const noAdd = !(allowAdd && !maximumReached);
  const addButtonLocation = noAdd || (!(
    addButtonAboveList
    || addButtonTitle
    || passAddToChildInstead
    || setHeaderActions
    || endAddButtonText
    || renderBelowWithAddAction
  ) && autoAddNew)
    ? ''
    : renderBelowWithAddAction
      ? 'renderBelowPassAdd'
      : passAddToChildInstead
        ? 'inChild'
        : addButtonAboveList
          ? 'aboveList'
          : addButtonTitle
            ? 'inHeader'
            : 'atBottom';

  const genChild = (a: {id?: string}, attrs: {aIter: number, aLength: number}, opts?: {useOverride?: any, injector?: boolean, isNewRow?: boolean}) => {
    const { aIter, aLength } = attrs;
    const { isNewRow, useOverride, injector = false } = opts ?? {};
    // We do need to keep the key in this order for the save of entries being filled, but on remove
    // this will cause the input value to persist...
    const key = `${autoAddNew ? aIter : ((a?.replacingId ?? a.id) ?? aIter)}`;

    const orderInstance = orderList && orderList.find(or=>or.id===key);
    const childReadonly = isChildReadonly ? isChildReadonly(a) : false;
    const removable = !childReadonly && !injector && !readOnly && !((autoAddFirst && !autoAddNew) && (typeof autoAddFirst === 'number' ? itemCount <= autoAddFirst : itemCount === 1));
    const furtherProperties: Partial<EditorListChildProps> = {};
    if (addButtonLocation === 'inChild') {
      furtherProperties.onInsert = handleInsert;
    }
    const editable = !childReadonly && !injector && !readOnly;
    return {
      dragDisable: (orderInstance && orderInstance.state!==DataGeneration.Added) || false,
      key,
      jsx: (
        <div key={key} className={clsJn('w-100', composeErrorPathClassName(fullPath, undefined))}>
          {hrEnable && aIter > 0 && <hr className='mt-5' />}
          {!compact && aIter>0 && <hr />}
          <div className='d-flex align-items-center'>
            {expansionTreeMarks && <div style={{ fontSize: '1.75rem' }} className='me-3'>{aIter < valueList.length-1 ? '├' : '└'}</div>}
            <ChildElement
              hideDelete={!allowAdd}
              removable={removable}
              editable={editable}
              parentPath={fullPath}
              myPath={injector ? `[${aIter}]` : `[${a.id ?? aIter}]`}
              autoFocus={hasInserted && aIter === valueList.length-1}
              primaryId={primaryIdValue}
              primaryIdAbsPath={normalisePathToStr(primaryIdAbsPath)}
              onDelete={onDelete}
              onUpdate={onUpdate}
              onUpdateBegin={onUpdateBegin}
              onUpdateCancel={onUpdateCancel}
              myIndex={aIter}
              collectionLength={aLength}
              thisLevel={level}
              duplicate={duplicateItems.includes(a)}
              key={key}
              overrideValue={useOverride ? a : undefined}
              orderInstance={orderInstance}
              collectionRemovedList={(Array.isArray(removedList) && removedList.length && removedList)||undefined}
              onCollectionRestoreItem={(Array.isArray(removedList) && removedList.length && restoreItemHandler)||undefined}
              onCollectionRemoveItemHereForChild={removeCollectionItemAnyChildPath}
              isHeader={hasHeaderRow && aIter===-1}
              isNewRow={isNewRow}
              {...a}
              {...childProps}
              {...furtherProperties}
            />
          </div>
        </div>
      )
    };
  };

  const childList: {jsx: any, key: string, dragDisable?: boolean}[] = [];
  hasHeaderRow && childList.push(genChild({ id: '' }, { aIter: -1, lastIter: -1 }));
  let prevSection = '';
  let cumCost = 0;

  childList.push(
    ...(annexurePreviousList
      ? annexurePreviousList.map(
        (a,aIter: number, arr)=>genChild(a.data,{ aIter, aLength: arr.length },{ useOverride: [DataGeneration.CarriedOver, DataGeneration.Restored].includes(a.state) })
      )
      : valueList.flatMap((a,aIter: number, o) => {
        const items = [];
        if (sectionProperty) {
          //new section, display previous section subtotal and new section sub-heading
          if (prevSection !== a[sectionProperty]) {
            prevSection && items.push({
              key: `${prevSection}-subtotal`,
              jsx: <Row><Col className={'w-100 d-flex justify-content-end me-5 mt-2'}>{canonicalisers.audWithNegative(cumCost.toString()).display}</Col></Row>
            });
            items.push({
              key: `${a[sectionProperty]}-subheading`,
              jsx: <div className='fs-4 scrollspy-target subsection mb-0' data-focus-path={`subsection-sub-${a[sectionProperty]?.replace(' ', '-')}`} >{a[sectionProperty]}</div>
            });
            cumCost = 0;
          }
          prevSection = a[sectionProperty];
          cumCost += (a?.enable ? a?.itemCost||0 : 0);
        }
        const isNewRow = sectionProperty && prevSection !== valueList[aIter+1]?.[sectionProperty] || false;
        return [...items, genChild({ id: a.id },{ aIter, aLength: o.length },{ isNewRow })];
      })
    )
  );
  const previousCount = usePrevious(childList.length);
  const elementCountDiff = childList.length - (previousCount??0);

  useEffect(()=>{
    // Basically, we want to show no auto inserted row for one render cycle after a delete.
    // Doing so will prevent an element with an old key hold on to data and carry it to the new
    // auto suggest row, because for one render cycle, it doesn't exist. The whole reason we use
    // positional keys in the first place is so that we don't lose the cursor when the auto suggest
    // goes from a non-existent entry to an existent one, and then having a uuid as a key.
    // This is a very ugly way to do this, being that we use 2 render cycles just to achieve this.
    // I haven't yet thought of a universal way to do this without also having to modify any
    // potential child element.
    // Also unfortunate that it causes a flash. But hey it works, and it will only occur on
    // deletions which have auto rows anyway
    if (blockNewAutoRow) setBlockNewAutoRow(false);

  }, [blockNewAutoRow]);

  useEffect(()=>{
    if (elementCountDiff < 0) {
      setBlockNewAutoRow(true);
    }
  }, [elementCountDiff]);

  // Prevent UUID changing on every rerender
  const suggestRowPlaceholder = useMemo(()=>({ id: uuid() }), [childList.length]);

  //Add final subtotal
  if (sectionProperty && prevSection) {
    childList.push({ key: `${prevSection}-subtotal`, jsx: <Row><Col className={'w-100 d-flex justify-content-end me-5 mt-2'}>{canonicalisers.audWithNegative(cumCost.toString()).display}</Col></Row> });
  } else {
    // Auto add row. Notice index is equal to length, meaning it is beyond existing value bounds
    autoAddNew && !blockNewAutoRow && !maximumReached && allComplete && childList.push(genChild(suggestRowPlaceholder, { aIter: valueList.length, aLength: valueList.length },{ injector: true }));
  }

  const baseListLen = childList.length;
  const restorationSection = removedList.length > 0 && <div className='mt-2'>

    <Button variant='link' className='accordion-removed-toggle' onClick={()=>{setShowRemoved(ps=>!ps);}}>
      {showRemoved?'Hide':'Show'} deleted items <Icon name={showRemoved ? 'expand_less' : 'expand_more'}/>
    </Button>

    <div className={clsJn({ 'pseudo-accordion': true, hide: !showRemoved })}>
      {removedList.map((item, idx)=>{
        const aIter = baseListLen+idx;
        const key = `${item.id ?? aIter}`;
        const content = typeof restorationFieldDisplay === 'string'
          ? item?.[restorationFieldDisplay??'']
          : restorationFieldDisplay?.(item);
        const restorable = isChildReadonly ? !isChildReadonly(item) : true;
        return <div key={key} className='d-flex align-items-center'>{content} {restorable && <Button variant='link' onClick={()=>restoreItemHandler(item)}>Restore</Button>}</div>;

      })}
    </div>

  </div>;

  const addCustom = (fn: ()=>any) => {
    insertItem(fn() ?? {});
    setHasInserted(true);
  };

  const addNewButton = overrideAdd?.length > 0
    ? <SplitButton variant='light' key='primary' title={overrideAdd[0]?.label} onClick={()=>addCustom(overrideAdd[0]?.fn)}>
      {overrideAdd?.slice(1)?.map(v => <Dropdown.Item key={v?.label} eventKey={v?.label} onClick={()=>addCustom(v.fn)} >{v?.label}</Dropdown.Item>)}
    </SplitButton>
    : addButton(()=>handleInsert(), endAddButtonText || '', addTooltip);

  useEffect(()=> {
    const action = allowAdd ? {
      label: endAddButtonText || '',
      tooltip: addTooltip,
      isPrimary: true,
      onClick: handleInsert
    } : undefined;

    setHeaderActions?.(p => {
      const result = { ...p };
      if (action) result[fullPath] = action;
      return (result);
    });

    return () => {
      setHeaderActions?.(p => {
        if (!p) return p;
        if (!p[fullPath]) return p;
        const result = { ...p };
        delete result[fullPath];
        return result;
      });
    };
  },[allowAdd, removedList, showRemoved]);

  const onDragEnd: (result: DropResult, provided: ResponderProvided) => void = result => {
    setIsDragging(false);
    updateDraft?.(draft => {
      if (!draft) {
        console.warn('Unable to update order, draft is undefined');
        return;
      }

      if (!result.destination) return;

      //carried over annexures are not in the data model, so offest the indexes to account for this
      const offset = annexurePreviousList?.filter(a => a.state === DataGeneration.CarriedOver)?.length||0;

      const [reorderedItem] = draft.splice(result.source.index - offset, 1);
      draft.splice(result.destination.index - offset, 0, reorderedItem);
      onReorder?.(draft);
    });
  };

  const onDragStart = () => {
    setIsDragging(true);
  };
  const restorationSectionMaybePortal = deletionListPortalRef?.current
    ? createPortal(restorationSection, deletionListPortalRef.current)
    : restorationSection;

  const dragAndDrop = useMemo(() =>
    <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={onDragStart}>
      <Droppable droppableId="collection">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {childList.map((c, idx) => c.dragDisable
              ? <div
                key={c.key}
                className={clsJn('d-flex align-items-start', dragAndDropConfig?.draggableClass)}
              >
                <div tabIndex={-1} className={dragAndDropConfig?.handleClass}>
                  <Icon pack='material-symbols' name='done' style={{ color: '#555', fontSize: '16pt', opacity: 0 }}/>
                </div>
                {c.jsx}
              </div>
              : <Draggable
                draggableId={c.key}
                index={idx}
                key={c.key}
              >
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    className={clsJn('d-flex align-items-start', dragAndDropConfig?.draggableClass)}
                  >
                    <div {...provided.dragHandleProps} tabIndex={-1} className={dragAndDropConfig?.handleClass}>
                      {/*
                        margin is used to align it with the delete/chevron, it might need to be revisited
                        if this component becomes more widely used - currently only used by clauses
                      */}
                      <Icon pack='material-symbols' name='drag_indicator' style={{ color: '#555', fontSize: '16pt', marginTop: 7 }}/>
                    </div>
                    {c.jsx}
                  </div>
                )}
              </Draggable>)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>, [values, orderList, childProps?.type]);

  return (<>

    <div className={clsJn(isDragging ? 'dragging' : '', collectionDisplayClassName)}>
      <div {...indentationParam}>
        {title && <div className='d-flex'>
          <div className={`d-flex align-items-center fs-${level+2}`} >{(titlePlural && valueList?.length > 1 ? titlePlural : title) ?? 'Collection'}{guidanceUnderTitle && <ShowGuidanceNotesButton onTrigger={() => setShowAdvice(true)} />}</div>
          {addButtonLocation === 'inHeader' && addButton(()=>handleInsert(),'',addTooltip)}
        </div>}
        {addButtonLocation === 'aboveList' && !setHeaderActions && !maximumReached && <div className='d-flex mb-2'>{addNewButton}</div>}
        {childList.length === 0 && emptyListErrorMessage && <div className={clsJn(emptyListClass, collectionEmptyError && 'fs-5', 'anywhere-invalid-feedback')} style={{ visibility: collectionEmptyError ? 'visible':'hidden' }}>{emptyListErrorMessage}</div>}
        <div className='collection-masker-parent'>
          {allowDragAndDrop && dragAndDrop}
          {!allowDragAndDrop && childList.map(c => ({ ...c.jsx , key: c.key }))}
          {restorationSectionMaybePortal}
          {collectionMaskingText && childList.length === 0 ? <div style={{ height: '3rem' }}></div> : undefined}
          {collectionMaskingText ? <div className='collection-masker fs-6 fw-bold'>{collectionMaskingText}</div> : undefined}
          {addButtonLocation === 'renderBelowPassAdd' && renderBelowWithAddAction?.(handleInsert)}
        </div>
      </div>
      {afterCollection ? afterCollection : ''}
      {addButtonLocation === 'atBottom' && !setHeaderActions && !maximumReached && <div className='list-end-add-btn w-100 justify-content-center d-flex'>{addNewButton}</div>}
      {guidanceUnderTitle && <Offcanvas show={showAdvice} onHide={()=>setShowAdvice(false)} placement='end'  className='guidance-notes' backdropClassName='guidance-notes-backdrop'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title><span className="h4">{guidanceUnderTitle?.title}</span></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='lead guidance-spacing'>
          {guidanceUnderTitle?.body}
        </Offcanvas.Body>
      </Offcanvas>}
    </div>
  </>);
};
