import { useEffect, useState } from 'react';

export function WrappedPlainElement({ element, onChange, onEdit }: {
  element: Element,
  onChange: (targetNames: string[]) => void,
  onEdit: (fieldName: string ) => void,
}) {
  const asInput = element as (HTMLInputElement | HTMLTextAreaElement);
  const [value, setValue] = useState('');

  useEffect(() => {
    if (element.classList.contains('custom-select') || element.type === 'checkbox') {
      const change = () => onChange([asInput.name]);
      element.addEventListener('change', change);
      return () => element.removeEventListener('change', change);
    } else {
      const focus = () => setValue(asInput.value);
      const change = () => onEdit(asInput.name);
      element.addEventListener('focus', focus);
      element.addEventListener('keyup', change);
      return () => {
        element.removeEventListener('focus', focus);
        element.removeEventListener('keyup', change);
      };
    }
  }, []);

  useEffect(() => {
    if (!(element.classList.contains('custom-select') || element.type === 'checkbox')) {
      const blur = () => {
        if (asInput.value === value) return;
        onChange([asInput.name]);
      };
      element.addEventListener('blur', blur);
      return () => element.removeEventListener('blur', blur);
    }
  }, [value]);

  return <></>;
}
