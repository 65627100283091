import { FloatingLabel, Form } from 'react-bootstrap';
import { useEffect, useState } from 'react';

interface Props {
  setValid: (valid: boolean) => void;
  setValue: (cvn: string) => void;
}

export function CardCvn({
  setValid,
  setValue
}: Props) {
  const [cardCvn, setCardCvn] = useState('');
  const [flagCvnInvalid, setFlagCvnInvalid] = useState(false);
  const [cvnValid, setCvnValid] = useState(false);

  useEffect(() => {
    setValid(cvnValid);
    setValue(cardCvn);
  }, [cardCvn]);

  return <FloatingLabel label='CVV' className='flex-grow-1 common-label'>
    <Form.Control
      placeholder=''
      type='number'
      minLength={2}
      maxLength={4}
      autoComplete='cc-csc'
      isInvalid={flagCvnInvalid && !cvnValid}
      onChange={(e) => {
        setFlagCvnInvalid(false);
        const mutableValue = e.target.value.replace(/\D/g, '');
        setCvnValid(mutableValue.length === 3);
        setCardCvn(mutableValue);
      }}
      onBlur={(e) => {
        if (!cvnValid) {
          setFlagCvnInvalid(true);
        }

        setValue(cardCvn);
        setValid(cvnValid);
      }}
    />

    <div className='invalid-feedback'>Please enter the 3 digit CVV on the back of the card</div>
  </FloatingLabel>;
}
