import { CardLogo, CardType } from '../CardLogo';

interface Props {
  cardType: CardType;
  lastFour: string;
  expiry: string;
  name: string;
}

export function CardOnRecord({
  cardType,
  lastFour,
  expiry,
  name
}: Props) {

  return <div>

    <div className='d-flex align-items-center'>
      <CardLogo cardType={cardType} style={{ fontSize: '32px' }} />
      <h5 className='m-0 ms-2'>Stored Credit Card Details</h5>
    </div>

    <p>
      <strong>{name}</strong><br />
      xxxx xxxx xxxx {lastFour}<br />
      {expiry}
    </p>
  </div>;
}
