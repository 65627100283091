import { AuthApi } from '@property-folders/common/client-api/auth';
import { AgentSessionInfoResult } from '@property-folders/contract';

type Entity = AgentSessionInfoResult['entities'][0];

export function useIsManager(
  opts: { entityId: number } | { entityUuid: string } | { entity: AgentSessionInfoResult['entities'][0] | undefined }
): boolean {
  const { data: session } = AuthApi.useGetAgentSessionInfo();

  let entity: Entity | undefined;

  if ('entityId' in opts) {
    entity = session?.entities.find(e => e.entityId === opts.entityId);
  } else if ('entityUuid' in opts) {
    entity = session?.entities.find(e => e.entityUuid === opts.entityUuid);
  } else {
    entity = opts.entity;
  }

  return !!(entity?.roles.find(r => r === 'Manager'));
}
