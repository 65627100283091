import { Button, Modal } from 'react-bootstrap';
import { AsyncButton } from '../AsyncButton';

type Props = {
  show: boolean;
  cardId: string | number;
  onRemove: (cardId: string | number) => Promise<void>;
  onCancel: (cardId: string | number) => void;
  cardDetails: {lastFour: string;} | null
};

export function RemoveCardModal({
  show,
  cardId,
  onRemove,
  onCancel,
  cardDetails
}: Props) {
  return <Modal show={show}>
    <Modal.Header><h3>Remove Card</h3></Modal.Header>
    <Modal.Body>
      Are you sure you want to remove {cardDetails?.lastFour ? `the card ending in ${cardDetails?.lastFour}` : 'this card'}?
    </Modal.Body>
    <Modal.Footer>
      <Button variant='secondary' onClick={() => onCancel(cardId)}>Cancel</Button>
      <AsyncButton className='btn-danger' onClick={() => onRemove(cardId)}>Remove</AsyncButton>
    </Modal.Footer>
  </Modal>;
}
